
export const environment = {
   production: true,
   google: {
      GA_TRACKING_ID: 'G-50J7YSZJ00'
   },
   api: 'https://backend.sound-buttons.click/api',
   origin: 'https://sound-buttons.click',
   version: '0fdc9113328a8243769d0b1f5276a0b280ebcc66',
   CLARITY_TRACKING_ID: 'jnas47yqmj'
};
